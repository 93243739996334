import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
  const theme = useTheme();
  const PRIMARY_LIGHT = theme.palette.primary.light;
  const PRIMARY_MAIN = theme.palette.primary.main;
  const PRIMARY_DARK = theme.palette.primary.dark;

  const logo = (
    <Box sx={{ width: 40, height: 40, ...sx }}>
      <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 300.000000 300.000000">
        <defs>
          <linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
            <stop offset="0%" stopColor={PRIMARY_DARK} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
        </defs>
        <g
          fill={PRIMARY_MAIN} stroke="none" strokeWidth="1" fillRule="evenodd" transform="translate(0.000000,300.000000) scale(0.100000,-0.100000)">


<path d="M1681 2248 c-56 -10 -55 -28 2 -28 65 0 148 -58 178 -125 24 -51 25
-164 3 -249 -29 -113 -106 -246 -199 -345 -74 -80 -20 -44 84 55 171 163 269
322 279 453 7 110 -27 180 -111 222 -48 25 -157 33 -236 17z m229 -51 c85 -43
111 -144 69 -270 -20 -61 -94 -187 -109 -187 -4 0 3 35 16 78 27 86 32 198 12
265 -7 22 -34 64 -60 93 l-49 53 38 -5 c21 -2 58 -15 83 -27z"/>
<path d="M2085 2165 c4 -11 14 -36 21 -55 79 -210 -195 -544 -695 -847 -57
-35 -99 -63 -93 -63 24 0 297 132 397 191 205 122 330 243 402 387 32 64 37
85 41 160 4 94 -16 170 -59 222 -17 20 -19 21 -14 5z"/>
<path d="M1503 2155 c-95 -26 -263 -121 -335 -189 -15 -14 -35 -26 -45 -27
-12 0 -68 -78 -175 -245 l-157 -244 69 0 68 0 26 45 27 45 89 0 90 0 0 -45 0
-45 215 0 214 0 40 53 c55 74 101 151 101 171 0 9 -11 28 -25 41 -22 23 -31
25 -123 25 -113 0 -130 8 -112 56 6 16 17 32 23 36 7 4 65 8 129 8 l117 0 13
48 14 47 -126 3 c-204 5 -254 -19 -291 -138 -40 -129 -11 -160 146 -160 l95 0
0 -26 c0 -14 -5 -34 -10 -45 -10 -17 -23 -19 -155 -19 l-145 0 0 28 c0 15 -8
102 -17 194 l-17 167 -46 3 -45 3 55 33 c129 76 290 112 409 92 79 -13 124
-39 158 -89 l23 -34 0 29 c0 78 -46 155 -107 180 -41 18 -123 17 -190 -1z
m-305 -263 c8 -6 19 -66 30 -170 16 -142 21 -164 41 -186 13 -14 35 -27 50
-28 20 -3 15 -6 -21 -12 -65 -11 -90 -3 -112 39 -11 19 -25 35 -33 35 -18 0
-16 10 8 36 41 44 21 185 -29 202 -17 5 -62 -46 -96 -110 l-30 -55 23 -31 22
-32 -26 0 c-35 0 -64 -16 -94 -51 -21 -26 -51 -39 -51 -23 0 6 46 81 90 149 8
13 42 65 74 117 32 51 68 101 79 111 20 18 54 22 75 9z m315 -9 c-12 -2 -36
-20 -53 -39 -40 -46 -40 -84 1 -118 27 -23 39 -26 109 -26 43 0 85 -3 94 -6
23 -9 19 -45 -9 -79 -23 -29 -24 -29 -25 -7 0 13 -11 34 -25 47 -22 23 -32 25
-110 25 -72 0 -88 3 -105 20 -21 21 -21 34 2 107 14 47 61 83 106 82 23 -1 28
-3 15 -6z m-370 -175 l7 -78 -55 0 c-30 0 -55 3 -55 6 0 3 13 24 29 47 15 23
36 57 45 75 10 19 19 32 20 30 2 -1 6 -38 9 -80z"/>
</g>
</svg>



    </Box>
  );
  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="">{logo}</RouterLink>;
}
